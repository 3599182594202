import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classNames from "classnames";

import { Button, Card, CardBody, Collapse, Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { dispatchEmptyAction, fetchElasticResponse, getAmenityCategories, getHideWidgetClass, getSelectedAmenities, isClientLoggedIn, isEqual } from "../../../components/utils";
import { getAdminMxtsEnv, getMxtsEnv } from "../../mxts/index";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getLabelOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { hideTooltip } from "../../../utils/generic.util";
import namespacesList from "../../../i18n/namespaceList";
import { renderTooltip } from "../../../utils/amenity.util";

interface DynamicAmenityFacetProps extends DynamicAmenityFacetBaseProps, AmenityFacetDispatchProps, AmenityFacetStoreProps, AmenityFacetParentProps {}

interface DynamicAmenityFacetBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    amenityCategories: MXTS.AmenityCategory[] | null;
}

interface AmenityFacetState {
    availableAmenityCategories: MXTS.AmenityCategory[];
    moreAmenityCategories: number[];
    expanded: number[];
    disableWidget: boolean;
    collapse: any;
    isOpen: boolean;
    selectedAmenity?: MXTS.Amenity;
    amenitiesModalPopup: boolean;
    isFetching: boolean;
    isCollapsed: boolean;
}

interface AmenityFacetStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    amenities: MXTS.ElasticFacetV2;
}

interface AmenityFacetDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction>;
}

interface AmenityFacetParentProps {
    notifyParentNoDynamicContentWasFound?: (child: unknown) => void;
    notifyParentTheDynamicContentFoundAgain?: (child: unknown) => void;
}

class AmenityFacetBase extends React.Component<DynamicAmenityFacetProps, AmenityFacetState> {
    private controller: AbortController = new AbortController();

    constructor(props: DynamicAmenityFacetProps) {
        super(props);
        this.state = {
            availableAmenityCategories: props.amenityCategories || [],
            moreAmenityCategories: [],
            disableWidget: true,
            collapse: {},
            isOpen: false,
            expanded: [],
            amenitiesModalPopup: false,
            isFetching: true,
            isCollapsed: false,
        };
        this.updateAmenities(props);
    }

    public componentDidMount() {
        const { dispatchAction } = this.props;
        dispatchEmptyAction(dispatchAction);
        this.setState({ disableWidget: !isClientLoggedIn(), isCollapsed: true });
    }

    componentDidUpdate(): void {
        const { selectedAmenity, isCollapsed, availableAmenityCategories } = this.state;
        const { dynamicFilter, dispatchAction } = this.props;
        if (!selectedAmenity) {
            const selectedAmenities = this.getSelectedAmenityByDynamicFilter();
            if (selectedAmenities) {
                this.setState({ selectedAmenity: selectedAmenities });
            }
            if (isCollapsed && this.props.options?.defaultCategory && availableAmenityCategories?.length) {
                this.setState({ isCollapsed: !isCollapsed });
                const amenityCategoryId = selectedAmenities?.amenityCategoryIds[0] || availableAmenityCategories[0].amenityCategoryId;
                this.toggle(amenityCategoryId);
            }
        }
        if (!dynamicFilter?.shouldFetchAvailability) {
            dispatchEmptyAction(dispatchAction);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<DynamicAmenityFacetProps>) {
        // To avoid unwanted update in amenities
        const { availabilityState, dynamicFilter, context, dispatchAction } = nextProps;
        const previousResult = this.props.availabilityState?.availabilityResult;
        let previousAmenities: MXTS.ElasticFacetV2 = [];
        let newAmenities: MXTS.ElasticFacetV2 = [];
        const selectedAmenity = this.getSelectedAmenityByDynamicFilter()!;
        if (!availabilityState.availabilityResult && !availabilityState.fetching) {
            fetchElasticResponse(context, dynamicFilter, dispatchAction, context.currentLocale.code);
        }
        if (previousResult && previousResult.response) {
            previousAmenities = previousResult.response.amenities;
        }

        if (this.state.availableAmenityCategories?.length) {
            this.updateAmenityDescription();
        }

        const nextResponse = nextProps.availabilityState.availabilityResult;
        if (nextResponse && nextResponse.response) {
            newAmenities = nextResponse.response.amenities!;
        }

        const { selectedAmenityCategory } = this.props.options;
        const nextSelectedAmenityCategory = nextProps.options.selectedAmenityCategory;
        if (
            (nextResponse && !isEqual(previousAmenities, newAmenities)) ||
            (nextProps.amenities.length && !isEqual(this.props.amenities, nextProps.amenities)) ||
            (selectedAmenityCategory && selectedAmenityCategory.length) !== (nextSelectedAmenityCategory && nextSelectedAmenityCategory.length)
        ) {
            this.updateAmenities(nextProps);
        }
        if (!isEqual(this.state.selectedAmenity, selectedAmenity)) {
            this.setState({ selectedAmenity });
        }
    }
    public componentWillUnmount() {
        this.controller.abort();
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            options,
            dynamicFilter,
        } = this.props;
        const { availableAmenityCategories, selectedAmenity } = this.state;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.textTitleColor?.includes("theme") ? `color-${options.textTitleColor}` : ""}`),
            style: options.textTitleColor?.includes("rgba") ? options.textTitleColor : "",
        };
        let amenitiesWrapper = "";
        let amenitiesGrids = "";
        const labelOptions = getLabelOptions(options, site, currentLocale);
        if (hideWidget === null) {
            return null;
        }
        if (!(options.displayType === "displayAsList") && !(options.displayType === "displayAsCollapsable")) {
            amenitiesWrapper = "row filters-grid";
            amenitiesGrids = "col-xl-3  col-lg-4 col-sm-6 filter";
        }

        const mapAvailableCategories = (): JSX.Element[] | null => {
            if (availableAmenityCategories?.length) {
                return availableAmenityCategories.map((category: MXTS.AmenityCategory) => {
                    if (options.useAsToggleFilter) {
                        return (
                            <div className={classNames("amenities-per-category amenities-as-preference", amenitiesGrids)} key={category.amenityCategoryId}>
                                {this.showAmenityList(category)}
                            </div>
                        );
                    }
                    return (
                        <div className={`amenities-per-category ${amenitiesGrids}`} key={category.amenityCategoryId}>
                            <LocalizedTitleAndLabel {...labelOptions} />
                            <label className="title">
                                <strong>{category.name}</strong>
                            </label>
                            {this.showAmenityList(category)}
                        </div>
                    );
                });
            }
            return null;
        };

        if (options.useAsToggleFilter) {
            availableAmenityCategories?.length &&
                availableAmenityCategories.map((category: MXTS.AmenityCategory) => (
                    <div className={`amenities-per-category ${amenitiesGrids}`} key={category.amenityCategoryId}>
                        {this.showAmenityList(category)}
                        <label className="title">
                            <strong>{category.name}</strong>
                        </label>
                    </div>
                ));
        }

        /* jscpd:ignore-start */
        return (
            <div className="search-filters-expanded">
                <LocalizedTitleAndLabel {...titleOptions} />
                <div className={`search-amenities-wrap filter-wrapper ${hideWidget} ${amenitiesWrapper}`}>
                    {this.state.isFetching && options.displayType === "displayAsCollapsable" ? (
                        <Loader type="search-amenities" />
                    ) : (
                        <React.Fragment>
                            {options.displayType === "displayAsCollapsable" &&
                                availableAmenityCategories?.length &&
                                availableAmenityCategories.map((category: MXTS.AmenityCategory) => {
                                    const amenityIdsInAcategory = category.amenities ? category.amenities.map((amenity: MXTS.Amenity) => amenity.identifier.toString()) : [];
                                    const selectedAmenities = amenityIdsInAcategory.filter((element: string) => dynamicFilter.amenities && dynamicFilter.amenities?.includes(element));

                                    return (
                                        !!category.amenities?.length && (
                                            <div
                                                // eslint-disable-next-line max-len
                                                className={classNames(`amenities-per-category ${category.name.toLowerCase().split(" ").join("-")}`)}
                                                key={category.amenityCategoryId}
                                            >
                                                {options.displayLabel ? (
                                                    <div className="category-label">
                                                        <strong>{category.name}</strong>
                                                    </div>
                                                ) : null}
                                                <div className="category-list-wrap">
                                                    <Button
                                                        color="primary"
                                                        onClick={this.toggle.bind(this, category.amenityCategoryId)}
                                                        // eslint-disable-next-line max-len
                                                        className={`${dynamicFilter.amenities?.length && selectedAmenities.length ? "active" : ""} ${options.displayIcon ? "category-icon" : ""}`}
                                                    >
                                                        {category.name}
                                                        {options.showAmenityCategoryInfo && renderTooltip(category?.name, category?.description, options.amenityCategoryFieldMultiSelect)}
                                                        <FontAwesome name={`${this.state.collapse[category.amenityCategoryId] ? "chevron-up" : "chevron-down"}`} />
                                                    </Button>
                                                    <Collapse isOpen={this.state.collapse[category.amenityCategoryId]}>
                                                        <Card>
                                                            <CardBody>
                                                                <LocalizedTitleAndLabel {...labelOptions} />
                                                                {this.showAmenityList(category)}
                                                            </CardBody>
                                                        </Card>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        )
                                    );
                                })}
                        </React.Fragment>
                    )}
                    {options.displayType === "displayAsList" && mapAvailableCategories()}
                    {options.displayType === "displayAsDropdown" && (
                        <div
                            // eslint-disable-next-line max-len
                            className={`dynamic-duration-facet search-filter-box ${options.addIcon ? "add-icon" : ""} ${
                                options.iconColor?.includes("theme") ? `color-${options.iconColor}` : options.iconColor?.indexOf("rgba") === -1 ? "icon-" + options.iconColor : ""
                            } ${options.iconOutside ? "icon-outside" : "icon-inside"} ${options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                                options.showArrow ? "show-arrows" : ""
                            } ${hideWidget}`}
                            style={{ color: options.addIcon && options?.iconColor.includes("rgba") ? options.iconColor : undefined }}
                        >
                            <Dropdown nav className="resort-inner search-filter-box--inner" isOpen={this.state.isOpen} toggle={this.toggleDropdown}>
                                <DropdownToggle
                                    nav
                                    tag="div"
                                    className={"search-filter-box--inner__label"}
                                    {...{ style: { color: options.addIcon && options?.iconColor.includes("rgba") ? options.iconColor : undefined } }}
                                >
                                    <span className="filter-data-wrap">
                                        <LocalizedTitleAndLabel {...labelOptions} />
                                        {selectedAmenity ? selectedAmenity.name : getI18nLocaleString(namespacesList.dynamicResort, "allResorts", currentLocale, site)}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu className="resort-dropdown search-filter-box--inner__dropdown">
                                    <div className="dropdown-inner">
                                        {!!availableAmenityCategories?.length &&
                                            availableAmenityCategories.map((category: MXTS.AmenityCategory, index) =>
                                                category.amenities?.length ? (
                                                    <div key={index} className="search-window__item">
                                                        {this.showAmenityList(category)}
                                                    </div>
                                                ) : null
                                            )}
                                    </div>
                                </DropdownMenu>
                                <div className={`${this.state.isOpen ? "backdrop-popup" : ""}`} onClick={this.state.isOpen ? this.toggleDropdown : undefined}></div>
                            </Dropdown>
                        </div>
                    )}
                    {options.displayType === "displayModalPopup" && (
                        <div
                            className={`dynamic-duration-facet search-filter-box ${options.addIcon ? "add-icon" : ""} ${
                                options.iconColor?.includes("theme") ? `color-${options.iconColor}` : options.iconColor?.indexOf("rgba") === -1 ? "icon-" + options.iconColor : ""
                            } ${options.iconOutside ? "icon-outside" : "icon-inside"} ${options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                                options.showArrow ? "show-arrows" : ""
                            } ${hideWidget}`}
                            style={{ color: options.addIcon && options.iconColor?.includes("rgba") ? options.iconColor : undefined }}
                            onClick={this.handleAmenitiesInfoModal}
                        >
                            <div className="resort-inner" style={{ color: options.addIcon && options.iconColor?.includes("rgba") ? options.iconColor : undefined }}>
                                <a className="search-filter-box--inner__label resort-label">
                                    <span className="filter-data-wrap ">
                                        <LocalizedTitleAndLabel {...labelOptions} />
                                        {selectedAmenity ? selectedAmenity.name : getI18nLocaleString(namespacesList.dynamicResort, "allResorts", currentLocale, site)}
                                    </span>
                                    {this.amenitiesModal()}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private getSelectedAmenityByDynamicFilter(): MXTS.Amenity | null {
        const { dynamicFilter } = this.props;
        const { availableAmenityCategories } = this.state;
        if (dynamicFilter?.amenities?.length) {
            let allAmenities: MXTS.Amenity[] = [];
            availableAmenityCategories.forEach((category: MXTS.AmenityCategory) => {
                allAmenities = [...allAmenities, ...(category.amenities || [])];
            });
            const selectedAmenities = allAmenities.filter((amenity: MXTS.Amenity) => dynamicFilter.amenities?.find((selectedAmenityId: string) => `${amenity.identifier}` === selectedAmenityId));
            if (selectedAmenities.length) {
                return selectedAmenities[selectedAmenities?.length - 1];
            }
        }
        return null;
        /* jscpd:ignore-end */
    }

    private toggleDropdown = () => {
        this.setState({ isOpen: !this.state.isOpen });
        hideTooltip();
    };

    private handleAmenity = (amenity: MXTS.Amenity, event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ selectedAmenity: event.target.checked ? amenity : undefined });
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: event.target.checked ? dynamicFilterType.addamenity : dynamicFilterType.removeamenity,
            payload: {
                amenities: [amenity.identifier.toString()],
            },
        };
        this.props.dispatchAction(action);
    };

    private clearAllAmenity = (): void => {
        if (this.state.selectedAmenity) {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.removeamenity,
                payload: {
                    amenities: [this.state.selectedAmenity?.amenityId?.toString() || ""],
                },
            };
            this.setState({ selectedAmenity: undefined });
            this.props.dispatchAction(action);
        }
    };

    private async updateAmenities(props: DynamicAmenityFacetProps): Promise<void> {
        const result = await updateAmenities(props, this.controller);
        if (result) {
            this.setState(result);
        }
    }

    private handleShowMoreAmenities = (amenityCategoryId: number) => {
        const { moreAmenityCategories, expanded } = this.state;
        let newExpanded: number[] = [];
        let newCategories: number[] = [];
        if (expanded.indexOf(amenityCategoryId) === -1) {
            newCategories.push(...moreAmenityCategories, amenityCategoryId);
            newExpanded.push(...expanded, amenityCategoryId);
        } else if (expanded?.includes(amenityCategoryId)) {
            newCategories = moreAmenityCategories.filter((categoryId) => categoryId !== amenityCategoryId);
            newExpanded = expanded.filter((id) => id !== amenityCategoryId);
        }
        this.setState({ moreAmenityCategories: newCategories, expanded: newExpanded });
    };

    private showAmenityList = (category: MXTS.AmenityCategory): JSX.Element => {
        const {
            dynamicFilter,
            options,
            context: { currentLocale, site },
        } = this.props;
        const { expanded, selectedAmenity } = this.state;
        const amenitiesRegExp = new RegExp("\\$amenities");
        const currentLocalized = getLocalizedContent({ currentLocale, site, localizedContent: options.localizedContent });

        return (
            <div className={"amenity-list"}>
                {this.props.availabilityState.fetching && <div className="amenity-overlay" />}
                {this.props.availabilityState.fetching && <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />}
                {options.displayShowAll && (
                    <label htmlFor="clear-all" className="amenity action-filters" onClick={this.clearAllAmenity}>
                        <input type="checkbox" id="clear-all" className="form-check-input" />
                        <span className="amenityName">{currentLocalized?.showAllText || getI18nLocaleString(namespacesList.searchAmenities, "showAll", currentLocale, site)}</span>
                    </label>
                )}
                {category.amenities &&
                    category.amenities
                        .slice(0, options.nrOFAmenitiesByDefault && expanded.indexOf(category.amenityCategoryId) === -1 ? options.nrOFAmenitiesByDefault : category.amenities.length)
                        .map((amenity) => {
                            const isSelected = !(dynamicFilter?.amenities?.length === 1 && options.useAsSingularFilter && amenity.identifier !== selectedAmenity?.identifier);
                            const amenityInput = (
                                <input
                                    type="checkbox"
                                    onChange={this.handleAmenity.bind(this, amenity)}
                                    id={amenity.identifier.toString()}
                                    disabled={amenity.isUnavailable || this.props.availabilityState.fetching || !isSelected}
                                    className={classNames("form-check-input")}
                                    // If for the same amenity, results are already filtered based on USP amenities filter, then checking this out
                                    checked={dynamicFilter.amenities && dynamicFilter.amenities.includes(amenity.identifier.toString())}
                                />
                            );
                            const infoTooltip = options.showAmenityInfo && renderTooltip(amenity.name, amenity.description, options.amenityFieldMultiSelect);
                            const amenityName = <span className="amenityName">{amenity.name}</span>;
                            return options.showUnavailableAmenities || isSelected ? (
                                <label htmlFor={amenity.identifier.toString()} className="amenity" key={amenity.identifier.toString()}>
                                    {options.useAsToggleFilter ? (
                                        <React.Fragment>
                                            {amenityInput}
                                            <div className={"toggle-fill"}></div>
                                            {amenityName}
                                            {infoTooltip ? infoTooltip : ""}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {amenityInput}
                                            {amenityName}
                                            {infoTooltip ? infoTooltip : ""}
                                        </React.Fragment>
                                    )}
                                    {amenity.count && <span className="amenity-count">{amenity.count}</span>}
                                </label>
                            ) : null;
                        })}
                {category.amenities && options.nrOFAmenitiesByDefault > 0 && category.amenities.length > options.nrOFAmenitiesByDefault && expanded.indexOf(category.amenityCategoryId) === -1 ? (
                    <a className="show-more-filters" onClick={this.handleShowMoreAmenities.bind(this, category.amenityCategoryId)}>
                        {currentLocalized && options.toggleAmenities && currentLocalized.moreAmenitiesButtonText}
                        {options.toggleAmenities &&
                            options.attachCategoryNameWithLabel &&
                            getI18nLocaleString(namespacesList.widgetSearchfacet, "amenityCategory", currentLocale, site).replace(amenitiesRegExp, " " + category.name)}
                        {(!currentLocalized || !(options.toggleAmenities && currentLocalized.moreAmenitiesButtonText)) && <FontAwesome name="plus" />}
                        {(!currentLocalized || !(options.toggleAmenities && currentLocalized.moreAmenitiesButtonText)) &&
                            getI18nLocaleString(namespacesList.widgetSearchfacet, "allAmenities", currentLocale, site).replace(amenitiesRegExp, category.name)}
                    </a>
                ) : category.amenities && expanded?.includes(category.amenityCategoryId) && options.toggleAmenities && currentLocalized && currentLocalized.lessAmenitiesButtonText ? (
                    <a className="show-more-filters" onClick={this.handleShowMoreAmenities.bind(this, category.amenityCategoryId)}>
                        {currentLocalized.lessAmenitiesButtonText}
                        {options.attachCategoryNameWithLabel &&
                            getI18nLocaleString(namespacesList.widgetSearchfacet, "amenityCategory", currentLocale, site).replace(amenitiesRegExp, " " + category.name)}
                    </a>
                ) : null}
            </div>
        );
    };

    private toggle = (id: number) => {
        const collapse = { ...this.state.collapse, [id]: !this.state.collapse[id] };
        this.setState({ collapse });
    };
    private handleAmenitiesInfoModal = () => {
        this.setState({ amenitiesModalPopup: !this.state.amenitiesModalPopup });
    };

    private async updateAmenityDescription() {
        const { availableAmenityCategories } = this.state;
        const env = await getAdminMxtsEnv();
        const amenityCategoriesIds = availableAmenityCategories.map((categories: MXTS.AmenityCategory) => categories.amenityCategoryId);
        const amenityCategories = await this.props.context.mxtsApi
            .amenityCategories(env, {
                size: 2000,
                amenityCategoryId: amenityCategoriesIds,
                isInternalUse: false,
                view: "detail",
                sort: "priority",
            })
            .then((cat: MXTS.PagedResult<MXTS.AmenityCategory>) => cat.content);

        const updateAvailableAmenityCategories = availableAmenityCategories.map((availableCategories: MXTS.AmenityCategory) => {
            const description = amenityCategories.find((categories: MXTS.AmenityCategory) => availableCategories.amenityCategoryId === categories.amenityCategoryId)?.description;
            return { description, ...availableCategories };
        });
        this.setState({ availableAmenityCategories: updateAvailableAmenityCategories });
    }

    /* jscpd:ignore-start */
    private amenitiesModal = () => {
        const { availableAmenityCategories, amenitiesModalPopup } = this.state;
        const { currentLocale, site } = this.props.context;

        return (
            <Modal isOpen={amenitiesModalPopup} toggle={this.handleAmenitiesInfoModal} className={"amenites-on-modal modal-lg"}>
                <ModalHeader tag="h4" toggle={this.handleAmenitiesInfoModal} className="no-background">
                    {getI18nLocaleString(namespacesList.searchAmenities, "amenitiesModal", currentLocale, site)}
                </ModalHeader>
                <ModalBody>
                    <div className="search-filters-expanded">
                        <div className="search-amenities-wrap filter-wrapper">
                            {!!availableAmenityCategories?.length &&
                                availableAmenityCategories.map((category: MXTS.AmenityCategory, index) =>
                                    category.amenities?.length ? (
                                        <div key={index} className="search-window__item">
                                            {this.showAmenityList(category)}
                                        </div>
                                    ) : null
                                )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
        );
    };
    /* jscpd:ignore-start */
}

async function updateAmenities(props: DynamicAmenityFacetProps, abortController?: AbortController): Promise<any> {
    const { amenityCategories, options, context, notifyParentNoDynamicContentWasFound, notifyParentTheDynamicContentFoundAgain } = props;
    if (!props.availabilityState?.availabilityResult) {
        return;
    }
    const { response } = props.availabilityState.availabilityResult!;
    let amenities: MXTS.ElasticFacetV2 = [];
    if (response) {
        amenities = response.amenities;
        let availableAmenityCategories = [];
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const selectedAmenityCategories = (
            await getAmenityCategories(
                context.mxtsApi,
                env,
                options.selectedAmenityCategory.map((amenityCategory) => amenityCategory.value)
            )
        ).map((amenityCategory) => ({ label: amenityCategory.name, value: amenityCategory.amenityCategoryId }));

        const arrangedAmenityCategories: NumberMultiSelectOption[] = [];
        options.selectedAmenityCategory.forEach((amenityCategory) =>
            selectedAmenityCategories.forEach((amenityCategories) => {
                if (amenityCategories.value === amenityCategory.value) {
                    arrangedAmenityCategories.push(amenityCategories);
                }
            })
        );

        // If no pre-selected category, select all categories
        // Else, getting all the categoriesIds from widget options and putting them in "amenities-by-category" array
        availableAmenityCategories = amenityCategories
            ? JSON.parse(JSON.stringify(amenityCategories))
            : await getSelectedAmenities(context.mxtsApi, arrangedAmenityCategories, abortController?.signal, env);

        // Check for unavailable checkbox
        availableAmenityCategories!.forEach((category: MXTS.AmenityCategory) => {
            if (category.amenities) {
                if (!options.showUnavailableAmenities) {
                    category.amenities = category.amenities.filter((amenity: MXTS.Amenity) => amenities.some((_amenity) => _amenity.key === amenity.amenityId));
                } else {
                    category.amenities.forEach((amenity: MXTS.Amenity) => {
                        const shouldBeDisabled = amenities.some((_amenity) => _amenity.key === amenity.amenityId);
                        amenity.isUnavailable = !shouldBeDisabled;
                    });
                }
            }
        });
        const filteredAmenities = props.amenities.length ? props.amenities : props?.availabilityState?.availabilityResult?.response?.amenities;
        if (filteredAmenities?.length && filteredAmenities?.[0]?.count) {
            availableAmenityCategories.forEach((category: MXTS.AmenityCategory) => {
                if (category.amenities) {
                    category.amenities.forEach((ame) => {
                        const isAvailable = filteredAmenities.some((amenity) => amenity.key === ame.amenityId);
                        ame.isUnavailable = !isAvailable;
                    });
                }
                updateAmenityCounts(filteredAmenities, category);
            });
        }
        if (notifyParentNoDynamicContentWasFound) {
            availableAmenityCategories = availableAmenityCategories.filter((category: MXTS.AmenityCategory) => !category.amenities?.every((availableAmenity) => availableAmenity.isUnavailable));
            if (!availableAmenityCategories.length) {
                notifyParentNoDynamicContentWasFound(this);
            } else {
                notifyParentTheDynamicContentFoundAgain?.(this);
            }
        }
        return { availableAmenityCategories, isFetching: false };
    }
}

async function updateAmenityCounts(amenities: MXTS.ElasticFacetV2, category: MXTS.AmenityCategory) {
    category.amenities?.forEach((amenity: MXTS.Amenity) => {
        let count;
        if (!amenity.isUnavailable) {
            const amenityFound = amenities.find((_amenity) => _amenity.key === amenity.amenityId);
            if (amenityFound?.count) {
                count = amenityFound.count;
            }
        }
        amenity.count = count;
    });
}

function mapStateToPorps(state: State): AmenityFacetStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        amenities: state.amenitiesState.amenities,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction>): AmenityFacetDispatchProps {
    return { dispatchAction: dispatch };
}

const AmenityFacet = connect<AmenityFacetStoreProps, AmenityFacetDispatchProps>(mapStateToPorps, mapDispatchToProps)(AmenityFacetBase);

export const DynamicAmenityFacet = wrapProps<DynamicAmenityFacetBaseProps>(AmenityFacet);

export async function warmupCache(props: DynamicAmenityFacetProps): Promise<void> {
    const result = await fetchElasticResponse(props.context, props.dynamicFilter, props.dispatchAction, props.context.currentLocale?.code);
    if (result) {
        props.availabilityState = {
            availabilityResult: result,
        };
    }
    await updateAmenities(props);
}
