import * as React from "react";

import { AmenityCategoryInfoOptions, AmenityInfoOption, getAmenityOptions } from "../../../utils/amenity.util";
import { DynamicAmenityFacet, warmupCache } from "./SearchAmenities";
import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import {
    LocalizedLabelOptions,
    LocalizedTitleOptions,
    getWidgetLabelOptions,
    getWidgetTitleOptions,
    labelStylingOptions,
    titleStylingOptions,
} from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { amenityCategoryMultiSelect, getAmenityCategories } from "../../../components/utils";

import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import { getMxtsEnv } from "../../mxts";
import { isServerSide } from "../../../utils/generic.util";
import { localized } from "../../../form-specs/utils";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions, LocalizedLabelOptions, AmenityInfoOption, AmenityCategoryInfoOptions {
    styleIds: string[];
    showUnavailableAmenities: boolean;
    nrOFAmenitiesByDefault: number;
    displayType: string;
    selectedAmenityCategory: NumberMultiSelectOption[];
    localizedContent: LocaleContent[];
    displayLabel: boolean;
    displayIcon: boolean;
    showArrow: boolean;
    arrowColor: string;
    addIcon: boolean;
    iconOutside: boolean;
    iconRight: boolean;
    iconColor: string;
    toggleAmenities: boolean;
    attachCategoryNameWithLabel: boolean;
    useAsSingularFilter?: boolean;
    displayShowAll?: boolean;
    showAllText?: LocaleContent[];
    textTitleColor?: string;
    useAsToggleFilter: boolean;
    textLabelColor?: string;
    defaultCategory?: boolean;
}

interface LocaleContent {
    moreAmenitiesButtonText: string;
    lessAmenitiesButtonText: string;
    locale: string;
    showAllText?: string;
}

const TARGET = ["search-amenities"];
export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "search-amenity",
    name: getI18nLocaleObject(namespaceList.dynamicContainer, "searchAmenity"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicContainer, "searchAmenities"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            amenityCategoryMultiSelect("selectedAmenityCategory"),
                            {
                                variable: "displayType",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "displayType"),
                                type: "select",
                                optionList: [
                                    {
                                        value: "displayAsList",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "displayAsList"),
                                    },
                                    {
                                        value: "displayAsDropdown",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "displayAsDropdown"),
                                    },
                                    {
                                        value: "displayAsCollapsable",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "displayAsCollapsable"),
                                    },
                                    {
                                        value: "displayModalPopup",
                                        label: getI18nLocaleObject(namespaceList.subject, "displayModalPopup"),
                                    },
                                ],
                            },
                            {
                                variable: "displayLabel",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "displayCategoryLabel"),
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === "displayAsCollapsable",
                            },
                            {
                                variable: "useAsToggleFilter",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "useAsToggleFilter"),
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === "displayAsList",
                                groupName: "preferenceToggleFilterGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.searchAmenities, "preferenceToggleFilterGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.searchAmenities, "preferenceToggleFilterGroupDescription"),
                            },
                            {
                                variable: "displayIcon",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "displayIcon"),
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === "displayAsCollapsable",
                            },
                            {
                                variable: "useAsSingularFilter",
                                label: getI18nLocaleObject(namespaceList.dynamicAccoKind, "useAsSingularFilter"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsToggleFilter,
                            },
                            {
                                variable: "displayShowAll",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "displayShowAll"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsToggleFilter,
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "showAllText",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "showAllText"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.displayShowAll,
                            }),
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "showUnavailableAmenitiesParagraph"),
                                groupName: "preferenceToggleFilterGroup",
                            },
                            {
                                variable: "showUnavailableAmenities",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "showUnavailableAmenities"),
                                groupName: "preferenceToggleFilterGroup",
                            },
                            {
                                variable: "nrOFAmenitiesByDefault",
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "nrOFAmenitiesByDefault"),
                            },
                            {
                                variable: "toggleAmenities",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "toggleAmenities"),
                                visible: (options: WidgetOptions) => !!+options.nrOFAmenitiesByDefault && !options.useAsToggleFilter,
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "moreAmenitiesButtonText",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "moreAmenitiesButtonText"),
                                        type: "text" as const,
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "showDefaultTextIfEmpty"),
                                        visible: (item: any, locale: any, options: WidgetOptions) => options.toggleAmenities,
                                    },
                                    {
                                        variable: "lessAmenitiesButtonText",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "lessAmenitiesButtonText"),
                                        type: "text" as const,
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "showDefaultTextIfEmpty"),
                                        visible: (item: any, locale: any, options: WidgetOptions) => options.toggleAmenities,
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.toggleAmenities && !!+options.nrOFAmenitiesByDefault,
                            }),
                            {
                                variable: "attachCategoryNameWithLabel",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "attachCategoryNameWithLabel"),
                                visible: (options: WidgetOptions) => options.toggleAmenities && !!+options.nrOFAmenitiesByDefault,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            ...getWidgetLabelOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    visible: (options: WidgetOptions) => !options.useAsToggleFilter,
                    properties: [
                        [multiSelectStylePicker("styleIds", TARGET)],
                        [
                            {
                                variable: "addIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "addIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !(options.displayType === "displayAsList" || options.displayType === "displayAsCollapsable"),
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => options.addIcon && !(options.displayType === "displayAsList" || options.displayType === "displayAsCollapsable"),
                            },
                            {
                                variable: "iconOutside",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconOutside"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) =>
                                    options.addIcon && !(options.displayType === "displayAsList" || options.displayType === "displayAsCollapsable" || options.displayType === "displayModalPopup"),
                            },
                            {
                                variable: "iconRight",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconRight"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.addIcon && !(options.displayType === "displayAsList" || options.displayType === "displayAsCollapsable"),
                            },
                            {
                                variable: "showArrow",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showArrow"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayAsDropdown",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>({ titleFontColorVariable: "textTitleColor" }),
                            ...labelStylingOptions<WidgetOptions>({ LabelFontColorVariable: "textLabelColor" }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "options"),
                    properties: [
                        [
                            {
                                variable: "defaultCategory",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "defaultCategory"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "display"),
                    properties: [[...getAmenityOptions<WidgetOptions>(true), ...getAmenityOptions<WidgetOptions>()]],
                },
            ],
        },
    ],
};

export const searchAmenitiesWidget: PageWidgetSpec<WidgetOptions> = {
    id: "search-amenity-widget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicContainer, "searchAmenity"),
    description: getI18nLocaleObject(namespaceList.dynamicContainer, "searchAmenityDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        showUnavailableAmenities: false,
        nrOFAmenitiesByDefault: 5,
        displayType: "displayAsList",
        selectedAmenityCategory: [],
        localizedContent: [],
        displayLabel: false,
        displayIcon: false,
        showArrow: false,
        arrowColor: "default",
        addIcon: false,
        iconColor: "default",
        iconOutside: false,
        iconRight: false,
        toggleAmenities: false,
        attachCategoryNameWithLabel: false,
        useAsToggleFilter: false,
        showAmenityInfo: false,
        amenityFieldMultiSelect: [],
        showAmenityCategoryInfo: false,
        amenityCategoryFieldMultiSelect: [],
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const isCategorySpecified = widget.options.selectedAmenityCategory?.length;
        // If a category is specified, then dont get all amenities by placing a request
        const amenityCategories = !isCategorySpecified ? await getAmenityCategories(context.mxtsApi, await getMxtsEnv(context, context.currentLocale.code)) : null;
        if (isServerSide()) {
            await warmupCache({
                dynamicContainerOptions,
                options: widget.options,
                context,
                amenityCategories,
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                amenities: [],
                availabilityState: {},
                dispatchAction: context.reduxStore.store.dispatch,
            });
        }
        return <DynamicAmenityFacet dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} amenityCategories={amenityCategories} />;
    },
};
